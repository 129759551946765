@import '../../styles/variables';

.Selectable{
	display: flex;
	align-items: center;
	font-size: 4vmin;
	margin: 1vmin;
	cursor: pointer;
	textarea:focus, input:focus{
		outline: none;
	}
	.Checkbox{
		margin: 0 1.2vmin;
	}
}


// shared SASS variables

$main-text: #181400;
// $main-background: #f1dfdf;
// $secondary-background: #f0e0dd;

// $main-background: #f1dfe0;
// $secondary-background: #fff6f0;
$main-background: #f3eee0;
$link-color: #040273; // #001146 #040273
$link-hover: #010fcc; // #0504aa

$positive-green: #88dd55;
$watermelon: #fd4659;
$pink-red: #f5054f;
$clear: rgba(0,0,0,0);

$accent: #f94450;

$default-margin: 2vmin;
$nav-height: 15vmin;

%main-font {
	font-family: 'Merriweather', 'Century Gothic', CenturyGothic, serif;
}

%full-page {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

%flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

%flex-column {
	@extend %flex-center;
	flex-direction: column;
}

%flex-horizontal-center {
  display: flex;
  align-items: center;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

%blink {
	animation: blink 1s step-start infinite;
}

// @keyframes backgroundpulse {
//   from {
//   	background-color: $main-background;
//   }
//   to {
//   	background-color: $secondary-background;
//   }
// }

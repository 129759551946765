@import '../styles/variables';

.Questions {
	position: relative;
	height: 80vh;
	min-width: 100vmin;
	max-width: 100vmin;
	.Button {
		position: absolute;
		bottom: 0;
		right: 0;
		margin-bottom: 4vmin;
	}
}
@import '../../styles/variables';

.Privacy{
	font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; 
	padding:1em;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: white;
	color: black;
}
@import '../styles/variables';

.UserInfo {
	margin: 0 2vmin;
	font-size: 4vmin;
	font-weight: 700;
	@extend %flex-horizontal-center;
	.UserInfo-firstname {
		color: $accent;
	}
	.UserInfo-age {
		margin: 0 1.5vmin;
	}
	.UserInfo-identification {
		margin-right: 1.5vmin;
		font-size: 3.6vmin;
	}
	.UserInfo-location {
		font-size: 3.6vmin;
		font-weight: 400;
	}
}

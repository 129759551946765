@import '../styles/variables';

.Profile {
	@extend %flex-column;
	.Header {
		width: 100vw;
		margin-bottom: 2vmin;
	}
	.Profile-title{
		font-size: 9vmin;
		margin: 3vmin 2vmin;
		text-align: center;
	}
	.Profile-viewmatches{
		margin: 10vmin;
	}
	.Profile-deleteaccount{
		margin: 6vmin 0 3vmin 0;
	}
}
@import '../styles/variables';

.Chat {
	@extend %flex-horizontal-center;
	color: $main-text;
	justify-content: space-between;
	width: 100%;
	.Chat-message{
		margin: 0 2vmin;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		opacity: 0.6;
		transition: opacity 0.2s ease-out;
	}
	&:hover{
		.Chat-message{
			opacity: 1;
		}
	}
}

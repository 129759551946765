@import '../styles/variables';

// $incomplete-color: #ee7733;  // #ff796c  #e17701  #ff5b00  #a03623  #fdaa48
$incomplete-color: #ff7755;  
$complete-color: $positive-green; // #76cd26  #96f97b  #89fe05

.ProfileSectionDisplay{
	@extend %flex-column;
	min-width: 70vmin;
	padding-bottom: 2.4vmin;
	.ProfileSectionDisplay-header{
		font-size: 6.4vmin;
		text-align: center;
		margin-bottom: 3vmin;
	}
	.ProfileSectionDisplay-body{
		display: flex;
		align-items: center;
		margin-left: 4vmin;
	}
	.ProfileSectionDisplay-bullet{
		color: $incomplete-color;
		width: 3.6vmin;
		height: 3.6vmin;
		@extend %flex-center;
		font-size: 10vmin;
		font-weight: 700;
		line-height: 3.6vmin;
		padding-bottom: 1vmin;
	}
	.ProfileSectionDisplay-status{
		font-size: 4vmin;
		margin: 0 1.6vmin;
	}
	.ProfileSectionDisplay-cta{
		color: $link-color;
		cursor: pointer;
		font-weight: 700;
		margin: 0 1.4vmin;
		font-size: 2vmin;
		&:hover{
			color: $link-hover;
		}
	}
	&.complete{
		.ProfileSectionDisplay-bullet{
			color: $complete-color;
			font-size: 7.2vmin;
			display: none;
		}
	}
	&.collapsed{
		@extend %flex-horizontal-center;
		flex-direction: row;
		padding: 5vmin 0 2vmin 0;
		.ProfileSectionDisplay-header{
			display: none;
		}
		.ProfileSectionDisplay-status{
			font-size: 5.4vmin;
			margin: 1vmin 1vmin 1vmin 2vmin;
			font-weight: 700;
			text-align: center;
		}
		.ProfileSectionDisplay-body{
			margin: 0;
		}
		.ProfileSectionDisplay-bullet{
			display: none;
		}
	}
}
@import '../styles/variables';

.PotentialMatches {
	@extend %flex-column;
	justify-content: space-between;
	height: 85vmin;
	.PotentialMatches-match{
		display: none;
		&.visible{
			display: block;
		}
	}
	.PotentialMatches-buttons{
		width: 100vmin;
		@extend %flex-horizontal-center;
		justify-content: space-between;
	}
}
@import '../styles/variables';

.PotentialMatch {
	@extend %flex-column;
	width: 100vmin;
	.PotentialMatch-info{
		margin: 0 2vmin;
		font-size: 4vmin;
		font-weight: 700;
		@extend %flex-horizontal-center;
		.PotentialMatch-info-firstname {
			color: $accent;
		}
		.PotentialMatch-info-age {
			margin: 0 1.5vmin;
		}
		.PotentialMatch-info-location {
			font-size: 3.6vmin;
			font-weight: 400;
		}
	}
	.PotentialMatch-question{
		width: 96vmin;
		padding: 2vmin;
		margin: 0 2vmin;
		.PotentialMatch-question-header{
			@extend %flex-horizontal-center;
			// justify-content: space-between;
			font-size: 4vmin;
			line-height: 0;
			font-weight: 700;
			margin: 4vmin 0 0 0;
			.Button{
				display: inline-block;
				margin: 0 0 0 3vmin;
			}
		}
		.PotentialMatch-question-text{
			font-size: 2.6vmin;
			margin-top: 0.4vmin;
			max-width: 77vw;
			font-weight: 700;
		}
		.PotentialMatch-answer-text{
			font-size: 6vmin;
			color: #f94450;
			margin-top: 1.4vmin;
		}
		.ReportUser{
			margin-top: 2vmin;
			.ReportUser-button{
				margin-left: auto;
			}
		}
		&.questionexpanded{
			.PotentialMatch-question-header{
				font-size: 3vmin;
			}
			.PotentialMatch-question-text{
				font-size: 6vmin;
				line-height: 7vmin;
				margin-top: 0;
			}
			.PotentialMatch-answer-text{
				font-size: 4vmin;
				font-weight: 400;
				margin-top: 2vmin;
			}
		}
	}
}
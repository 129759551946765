@import '../../styles/variables';

$superHugeNumber: 1000;

.GlobalLoader {
	@extend %full-page;
	@extend %flex-center;
	background-color: $main-background;
	// background-color: #5555ff;
	z-index: -1;
	opacity: 0;

	&.loading{
		z-index: $superHugeNumber;
		opacity: 1;
		.Logo{
			opacity: 1;
		}
	}
	&.loading-finished{
		opacity: 1;
		animation: fadeout 2s, allowClicks 1s;
		animation-delay: 1.2s;
		animation-fill-mode: forwards;

		z-index: -1;
		transition: z-index 0s 3s;

		.Logo{
			opacity: 1;
			animation: fadeout 1.2s;
			animation-fill-mode: forwards;
		}
	}
}

@keyframes fadeout {
	from { opacity: 1; }
	to	 { opacity: 0; }
}

@keyframes allowClicks {
	from { pointer-events: auto; }
	to	 { pointer-events: none; }
}

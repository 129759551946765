@import '../../../styles/variables';

.Button {
	@extend %main-font;
	@extend %flex-center;
	border: none;
	font-size: 4vmin;
	color: $main-background;
	background-color: $main-text;
	border-radius: 2.2vmin;
	padding: 2.4vmin 7vmin;
	text-transform: none;
	margin: 2vmin;
	transition: background-color 0.2s ease-out, color 0.2s ease-out;
	cursor: pointer;
	position: relative;
	&:focus {
		outline: none;
	}
	&:hover {
		background-color: $accent; // #242220;
		// color: white;
	}
	&.small{
		font-size: 2.4vmin;
		border-radius: 1.8vmin;
		padding: 0.8vmin 3.2vmin;
		margin: 0 1vmin;
		min-width: 16vmin;
	}
	&.medium{
		font-size: 3vmin;
		border-radius: 2.1vmin;
		padding: 1.1vmin 6vmin;
		min-width: 20vmin;
	}
}

@import '../styles/variables';

.Message {
	display: flex;
	font-weight: 700;
	&.you{
		justify-content: flex-end;
		text-align: right;
		color: $accent;
	}
	&.them{
		
	}
}
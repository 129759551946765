
@import 'styles/variables';

@import url('https://fonts.googleapis.com/css?family=Merriweather:400,700');

html {
	@extend %main-font;
	margin: 0 auto;
	font-size: 3vmin;
	color: $main-text;
	background: $main-background;
}

body, p, span{
	padding: 0;
	margin: 0;
}

body{
	// background-color: $main-background;
	height: 100%;
	width: 100%;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
}

a, a:link, a:visited, a:focus, a:hover, a:active{
	text-decoration: none;
}
a{
	color: $link-color;
}
@import '../../../styles/variables';

$dimensions: 4vmin;

.Checkbox{
	display: flex;
	position: relative;
	user-select: none;
	.Checkbox-box{
		cursor: pointer;
		appearance: none;
		margin: 0;
		height: $dimensions;
		width: $dimensions;
		border: 0.2vmin solid $main-text;
		box-sizing: border-box;
	}
	.Checkbox-checkmark {
		color: $positive-green;
		font-size: 6.8vmin;
		position: absolute;
		pointer-events: none;
		top: -2.8vmin;
		left: -0.2vmin;
		display: none;
	}
	&.checked {
		.Checkbox-box{
			border: none;
		}
		.Checkbox-checkmark{
			display: block;
		}
	}
}



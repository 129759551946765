@import '../styles/variables';

%gutter-margins {
	margin-left: $default-margin;
	margin-right: $default-margin;
}

.Chatroom {
	@extend %full-page;
	.Header{
		margin-bottom: 0;
	}
	.Chatroom-body{
		@extend %flex-center;
		.Chatroom-content{
			@extend %flex-column;
			width: 100vmin;
			.Chatroom-header {
				@extend %flex-horizontal-center;
				font-size: 3.6vmin;
				font-weight: 700;
				margin-bottom: 5vh;
			}
			.Chatroom-messages {
				height: 45vh;
				width: 100%;
				overflow: scroll;
				.Message {
					@extend %gutter-margins;
				}
			}
			.Chatroom-textarea {
				width: calc(100% - 4vmin);
				@extend %gutter-margins;
				.TextArea{
					height: 15vh;
				}
			}
		}
	}
}

@import '../../../styles/variables';

$text-color: $accent;

.IntegerInput {
	height: 100%;
	width: 100%;
	.IntegerInput-box {
		height: 100%;
		width: 100%;
		display: block;
	}
	.IntegerInput-caret {
		@extend %blink;
		background-color: $text-color;
		width: 0.6vmin;
		height: 5.8vmin;
		position: absolute;
		display: none;
	}
	.IntegerInput-input {
		@extend %main-font;
		height: 100%;
		width: 100%;
		background-color: $clear;
		border: none;
		font-size: 4vmin;
		color: $text-color;
		resize: none;
		overflow: hidden;
		&:focus {
			outline: none;
		}
		&::-webkit-inner-spin-button,
		&::-webkit-outer-spin-button{
			-webkit-appearance: none; 
			margin: 0; 
		}
	}
	&.showFakeCaret{
		.IntegerInput-caret {
			display: block;
		}
		.IntegerInput-input {
			caret-color: $clear;
		}
	}
}
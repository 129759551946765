@import '../styles/variables';

.QuestionForm {
	margin: 0 2vmin;
	.QuestionForm-title {
		font-size: 3vmin;
		line-height: 0;
		font-weight: 700;
		margin: 4vmin 0 0 0;
	}
	.QuestionForm-question {
		font-size: 6vmin;
		line-height: 7vmin;
		margin: 2vmin 0;
		max-width: 77vw;
	}
	.QuestionForm-answer {
		height: 50vmin;
		width: 100%;
	}
}
@import '../../styles/variables';

.Popup{
	@extend %full-page;
	z-index: 50;
	background-color: rgba(0,0,0,0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	.Popup-contents{
		@extend %flex-column;
		box-sizing: border-box;
		max-height: 80%;
		min-height: 45%;
		width: 35%;
		background: white;
		position: relative;
		text-align: center;
		justify-content: space-between;
		padding: 3%;
		overflow: hidden;
	}
}
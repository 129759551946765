@import '../styles/variables';

.ReportUserForm {
	@extend %flex-column;
	.TextArea {
		height: 28vh;
		min-width: 72vw;
		margin-bottom: 10vmin;
		.TextArea-textarea {
			text-align: center;
		}
	}
	.ReportUserForm-buttons{
		width: 100%;
		@extend %flex-horizontal-center;
		justify-content: space-between;
	}
}
@import '../styles/variables';

.LookingFor {
	@extend %flex-column;
	.LookingFor-header{
		// margin: 2vmin;
	}
	.Button {
		margin: 8vmin 0 4vmin 0;
	}
}
@import '../styles/variables';

.DeleteAccount {
	@extend %flex-column;
	.DeleteAccount-header{
		text-align: center;
		font-size: 9vmin;
		margin: 12vmin 2vmin 6vmin 2vmin;
	}
	.DeleteAccount-subheader{
		margin: 14vmin;
	}
	.Button {
		margin: 4vmin;
	}
}
@import '../styles/variables';

.Email {
	@extend %flex-column;
	.TextArea {
		margin-bottom: 10vmin;
		.TextArea-textarea {
			text-align: center;
		}
	}
}
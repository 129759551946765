@import '../styles/variables';

.Login {
	// color: #4267b2;
	min-height: 10vmin;
	opacity: 0;
	animation: fadein 0.6s;
	animation-delay: 0.6s;
	animation-fill-mode: forwards;
	@extend %full-page;
	@extend %flex-column;
	.Login-greeting{
		margin-top: 3.4vmin;
	}
	.Login-button{
		margin-top: 4vmin;
		opacity: 0;
		animation: fadein 2s;
		animation-delay: 4.4s;
		animation-fill-mode: forwards;
	}
	.Login-content{
		height: 0;
		animation: grow 0.6s;
		animation-delay: 3.6s;
		animation-fill-mode: forwards;
		animation-timing-function: ease-in-out;
	}
}

@keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}

@keyframes grow {
	from { height: 0; }
	to   { height: 6vmin; }
}
@import '../../styles/variables';

.Logo {
	@extend %flex-column;
	.Logo-header {
		margin: 0;
		font-size: 8vmin;
	}
	.Logo-subheader {
		font-size: 3.4vmin;
	}
}

@import '../styles/variables';

.Age {
	@extend %flex-column;
	max-width: 70vmin;
	.IntegerInput {
		width: inherit;
		margin-bottom: 10vmin;
		.IntegerInput-caret {
			height: 15vmin;
			width: 1.3vmin;
		}
		.IntegerInput-input {
			width: inherit;
			font-size: 10vmin;
			text-align: center;
		}
	}
}
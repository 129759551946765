@import url(https://fonts.googleapis.com/css?family=Merriweather:400,700);
html {
  font-family: 'Merriweather', 'Century Gothic', CenturyGothic, serif; }

@-webkit-keyframes blink {
  50% {
    opacity: 0; } }

@keyframes blink {
  50% {
    opacity: 0; } }

html {
  margin: 0 auto;
  font-size: 3vmin;
  color: #181400;
  background: #f3eee0; }

body, p, span {
  padding: 0;
  margin: 0; }

body {
  height: 100%;
  width: 100%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased; }

a, a:link, a:visited, a:focus, a:hover, a:active {
  text-decoration: none; }

a {
  color: #040273; }

.Logo {
  display: flex;
  align-items: center;
  justify-content: center; }

.Logo {
  flex-direction: column; }

@-webkit-keyframes blink {
  50% {
    opacity: 0; } }

@keyframes blink {
  50% {
    opacity: 0; } }

.Logo .Logo-header {
  margin: 0;
  font-size: 8vmin; }

.Logo .Logo-subheader {
  font-size: 3.4vmin; }

.Login {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; }

.Login {
  display: flex;
  align-items: center;
  justify-content: center; }

.Login {
  flex-direction: column; }

@-webkit-keyframes blink {
  50% {
    opacity: 0; } }

@keyframes blink {
  50% {
    opacity: 0; } }

.Login {
  min-height: 10vmin;
  opacity: 0;
  -webkit-animation: fadein 0.6s;
          animation: fadein 0.6s;
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }
  .Login .Login-greeting {
    margin-top: 3.4vmin; }
  .Login .Login-button {
    margin-top: 4vmin;
    opacity: 0;
    -webkit-animation: fadein 2s;
            animation: fadein 2s;
    -webkit-animation-delay: 4.4s;
            animation-delay: 4.4s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards; }
  .Login .Login-content {
    height: 0;
    -webkit-animation: grow 0.6s;
            animation: grow 0.6s;
    -webkit-animation-delay: 3.6s;
            animation-delay: 3.6s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out; }

@-webkit-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes grow {
  from {
    height: 0; }
  to {
    height: 6vmin; } }

@keyframes grow {
  from {
    height: 0; }
  to {
    height: 6vmin; } }

@-webkit-keyframes blink {
  50% {
    opacity: 0; } }

@keyframes blink {
  50% {
    opacity: 0; } }

.Checkbox {
  display: flex;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .Checkbox .Checkbox-box {
    cursor: pointer;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    margin: 0;
    height: 4vmin;
    width: 4vmin;
    border: 0.2vmin solid #181400;
    box-sizing: border-box; }
  .Checkbox .Checkbox-checkmark {
    color: #88dd55;
    font-size: 6.8vmin;
    position: absolute;
    pointer-events: none;
    top: -2.8vmin;
    left: -0.2vmin;
    display: none; }
  .Checkbox.checked .Checkbox-box {
    border: none; }
  .Checkbox.checked .Checkbox-checkmark {
    display: block; }

@-webkit-keyframes blink {
  50% {
    opacity: 0; } }

@keyframes blink {
  50% {
    opacity: 0; } }

.Selectable {
  display: flex;
  align-items: center;
  font-size: 4vmin;
  margin: 1vmin;
  cursor: pointer; }
  .Selectable textarea:focus, .Selectable input:focus {
    outline: none; }
  .Selectable .Checkbox {
    margin: 0 1.2vmin; }

.Button {
  font-family: 'Merriweather', 'Century Gothic', CenturyGothic, serif; }

.Button {
  display: flex;
  align-items: center;
  justify-content: center; }

@-webkit-keyframes blink {
  50% {
    opacity: 0; } }

@keyframes blink {
  50% {
    opacity: 0; } }

.Button {
  border: none;
  font-size: 4vmin;
  color: #f3eee0;
  background-color: #181400;
  border-radius: 2.2vmin;
  padding: 2.4vmin 7vmin;
  text-transform: none;
  margin: 2vmin;
  transition: background-color 0.2s ease-out, color 0.2s ease-out;
  cursor: pointer;
  position: relative; }
  .Button:focus {
    outline: none; }
  .Button:hover {
    background-color: #f94450; }
  .Button.small {
    font-size: 2.4vmin;
    border-radius: 1.8vmin;
    padding: 0.8vmin 3.2vmin;
    margin: 0 1vmin;
    min-width: 16vmin; }
  .Button.medium {
    font-size: 3vmin;
    border-radius: 2.1vmin;
    padding: 1.1vmin 6vmin;
    min-width: 20vmin; }

.Identification {
  display: flex;
  align-items: center;
  justify-content: center; }

.Identification {
  flex-direction: column; }

@-webkit-keyframes blink {
  50% {
    opacity: 0; } }

@keyframes blink {
  50% {
    opacity: 0; } }

.Identification .Button {
  margin: 8vmin 0 4vmin 0; }

.LookingFor {
  display: flex;
  align-items: center;
  justify-content: center; }

.LookingFor {
  flex-direction: column; }

@-webkit-keyframes blink {
  50% {
    opacity: 0; } }

@keyframes blink {
  50% {
    opacity: 0; } }

.LookingFor .Button {
  margin: 8vmin 0 4vmin 0; }

.IntegerInput .IntegerInput-input {
  font-family: 'Merriweather', 'Century Gothic', CenturyGothic, serif; }

@-webkit-keyframes blink {
  50% {
    opacity: 0; } }

@keyframes blink {
  50% {
    opacity: 0; } }

.IntegerInput .IntegerInput-caret {
  -webkit-animation: blink 1s step-start infinite;
          animation: blink 1s step-start infinite; }

.IntegerInput {
  height: 100%;
  width: 100%; }
  .IntegerInput .IntegerInput-box {
    height: 100%;
    width: 100%;
    display: block; }
  .IntegerInput .IntegerInput-caret {
    background-color: #f94450;
    width: 0.6vmin;
    height: 5.8vmin;
    position: absolute;
    display: none; }
  .IntegerInput .IntegerInput-input {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    font-size: 4vmin;
    color: #f94450;
    resize: none;
    overflow: hidden; }
    .IntegerInput .IntegerInput-input:focus {
      outline: none; }
    .IntegerInput .IntegerInput-input::-webkit-inner-spin-button, .IntegerInput .IntegerInput-input::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0; }
  .IntegerInput.showFakeCaret .IntegerInput-caret {
    display: block; }
  .IntegerInput.showFakeCaret .IntegerInput-input {
    caret-color: rgba(0, 0, 0, 0); }

.Age {
  display: flex;
  align-items: center;
  justify-content: center; }

.Age {
  flex-direction: column; }

@-webkit-keyframes blink {
  50% {
    opacity: 0; } }

@keyframes blink {
  50% {
    opacity: 0; } }

.Age {
  max-width: 70vmin; }
  .Age .IntegerInput {
    width: inherit;
    margin-bottom: 10vmin; }
    .Age .IntegerInput .IntegerInput-caret {
      height: 15vmin;
      width: 1.3vmin; }
    .Age .IntegerInput .IntegerInput-input {
      width: inherit;
      font-size: 10vmin;
      text-align: center; }

.TextArea .TextArea-textarea {
  font-family: 'Merriweather', 'Century Gothic', CenturyGothic, serif; }

@-webkit-keyframes blink {
  50% {
    opacity: 0; } }

@keyframes blink {
  50% {
    opacity: 0; } }

.TextArea .TextArea-caret {
  -webkit-animation: blink 1s step-start infinite;
          animation: blink 1s step-start infinite; }

.TextArea {
  height: 100%;
  width: 100%; }
  .TextArea .TextArea-box {
    height: 100%;
    width: 100%;
    display: block; }
  .TextArea .TextArea-caret {
    background-color: #f94450;
    width: 0.6vmin;
    height: 5.8vmin;
    position: absolute;
    display: none; }
  .TextArea .TextArea-textarea {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    font-size: 4vmin;
    color: #f94450;
    resize: none;
    overflow: hidden; }
    .TextArea .TextArea-textarea:focus {
      outline: none; }
  .TextArea.showFakeCaret .TextArea-caret {
    display: block; }
  .TextArea.showFakeCaret .TextArea-textarea {
    caret-color: rgba(0, 0, 0, 0); }

.Email {
  display: flex;
  align-items: center;
  justify-content: center; }

.Email {
  flex-direction: column; }

@-webkit-keyframes blink {
  50% {
    opacity: 0; } }

@keyframes blink {
  50% {
    opacity: 0; } }

.Email .TextArea {
  margin-bottom: 10vmin; }
  .Email .TextArea .TextArea-textarea {
    text-align: center; }

@-webkit-keyframes blink {
  50% {
    opacity: 0; } }

@keyframes blink {
  50% {
    opacity: 0; } }

.QuestionForm {
  margin: 0 2vmin; }
  .QuestionForm .QuestionForm-title {
    font-size: 3vmin;
    line-height: 0;
    font-weight: 700;
    margin: 4vmin 0 0 0; }
  .QuestionForm .QuestionForm-question {
    font-size: 6vmin;
    line-height: 7vmin;
    margin: 2vmin 0;
    max-width: 77vw; }
  .QuestionForm .QuestionForm-answer {
    height: 50vmin;
    width: 100%; }

@-webkit-keyframes blink {
  50% {
    opacity: 0; } }

@keyframes blink {
  50% {
    opacity: 0; } }

.Questions {
  position: relative;
  height: 80vh;
  min-width: 100vmin;
  max-width: 100vmin; }
  .Questions .Button {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: 4vmin; }

.Answers {
  display: flex;
  align-items: center;
  justify-content: center; }

.Answers {
  flex-direction: column; }

@-webkit-keyframes blink {
  50% {
    opacity: 0; } }

@keyframes blink {
  50% {
    opacity: 0; } }


.Location {
  display: flex;
  align-items: center;
  justify-content: center; }

.Location {
  flex-direction: column; }

@-webkit-keyframes blink {
  50% {
    opacity: 0; } }

@keyframes blink {
  50% {
    opacity: 0; } }

.Location .Button {
  margin: 8vmin 0 4vmin 0; }

@-webkit-keyframes blink {
  50% {
    opacity: 0; } }
@keyframes blink {
  50% {
    opacity: 0; } }

@-webkit-keyframes blink {
  50% {
    opacity: 0; } }
@keyframes blink {
  50% {
    opacity: 0; } }

.Header {
  display: flex;
  align-items: center; }

@-webkit-keyframes blink {
  50% {
    opacity: 0; } }

@keyframes blink {
  50% {
    opacity: 0; } }

.Header {
  justify-content: space-between;
  height: 10vmin; }
  .Header .Button {
    margin: 2vmin; }



.ProfileSectionDisplay, .ProfileSectionDisplay .ProfileSectionDisplay-bullet {
  display: flex;
  align-items: center;
  justify-content: center; }

.ProfileSectionDisplay {
  flex-direction: column; }

.ProfileSectionDisplay.collapsed {
  display: flex;
  align-items: center; }

@-webkit-keyframes blink {
  50% {
    opacity: 0; } }

@keyframes blink {
  50% {
    opacity: 0; } }

.ProfileSectionDisplay {
  min-width: 70vmin;
  padding-bottom: 2.4vmin; }
  .ProfileSectionDisplay .ProfileSectionDisplay-header {
    font-size: 6.4vmin;
    text-align: center;
    margin-bottom: 3vmin; }
  .ProfileSectionDisplay .ProfileSectionDisplay-body {
    display: flex;
    align-items: center;
    margin-left: 4vmin; }
  .ProfileSectionDisplay .ProfileSectionDisplay-bullet {
    color: #ff7755;
    width: 3.6vmin;
    height: 3.6vmin;
    font-size: 10vmin;
    font-weight: 700;
    line-height: 3.6vmin;
    padding-bottom: 1vmin; }
  .ProfileSectionDisplay .ProfileSectionDisplay-status {
    font-size: 4vmin;
    margin: 0 1.6vmin; }
  .ProfileSectionDisplay .ProfileSectionDisplay-cta {
    color: #040273;
    cursor: pointer;
    font-weight: 700;
    margin: 0 1.4vmin;
    font-size: 2vmin; }
    .ProfileSectionDisplay .ProfileSectionDisplay-cta:hover {
      color: #010fcc; }
  .ProfileSectionDisplay.complete .ProfileSectionDisplay-bullet {
    color: #88dd55;
    font-size: 7.2vmin;
    display: none; }
  .ProfileSectionDisplay.collapsed {
    flex-direction: row;
    padding: 5vmin 0 2vmin 0; }
    .ProfileSectionDisplay.collapsed .ProfileSectionDisplay-header {
      display: none; }
    .ProfileSectionDisplay.collapsed .ProfileSectionDisplay-status {
      font-size: 5.4vmin;
      margin: 1vmin 1vmin 1vmin 2vmin;
      font-weight: 700;
      text-align: center; }
    .ProfileSectionDisplay.collapsed .ProfileSectionDisplay-body {
      margin: 0; }
    .ProfileSectionDisplay.collapsed .ProfileSectionDisplay-bullet {
      display: none; }

.Profile {
  display: flex;
  align-items: center;
  justify-content: center; }

.Profile {
  flex-direction: column; }

@-webkit-keyframes blink {
  50% {
    opacity: 0; } }

@keyframes blink {
  50% {
    opacity: 0; } }

.Profile .Header {
  width: 100vw;
  margin-bottom: 2vmin; }

.Profile .Profile-title {
  font-size: 9vmin;
  margin: 3vmin 2vmin;
  text-align: center; }

.Profile .Profile-viewmatches {
  margin: 10vmin; }

.Profile .Profile-deleteaccount {
  margin: 6vmin 0 3vmin 0; }

.UserInfo {
  display: flex;
  align-items: center; }

@-webkit-keyframes blink {
  50% {
    opacity: 0; } }

@keyframes blink {
  50% {
    opacity: 0; } }

.UserInfo {
  margin: 0 2vmin;
  font-size: 4vmin;
  font-weight: 700; }
  .UserInfo .UserInfo-firstname {
    color: #f94450; }
  .UserInfo .UserInfo-age {
    margin: 0 1.5vmin; }
  .UserInfo .UserInfo-identification {
    margin-right: 1.5vmin;
    font-size: 3.6vmin; }
  .UserInfo .UserInfo-location {
    font-size: 3.6vmin;
    font-weight: 400; }

.Chat {
  display: flex;
  align-items: center; }

@-webkit-keyframes blink {
  50% {
    opacity: 0; } }

@keyframes blink {
  50% {
    opacity: 0; } }

.Chat {
  color: #181400;
  justify-content: space-between;
  width: 100%; }
  .Chat .Chat-message {
    margin: 0 2vmin;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    opacity: 0.6;
    transition: opacity 0.2s ease-out; }
  .Chat:hover .Chat-message {
    opacity: 1; }

.Chats {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; }

.Chats .Chats-content {
  display: flex;
  align-items: center;
  justify-content: center; }

.Chats .Chats-content {
  flex-direction: column; }

@-webkit-keyframes blink {
  50% {
    opacity: 0; } }

@keyframes blink {
  50% {
    opacity: 0; } }

@-webkit-keyframes blink {
  50% {
    opacity: 0; } }

@keyframes blink {
  50% {
    opacity: 0; } }

.Message {
  display: flex;
  font-weight: 700; }
  .Message.you {
    justify-content: flex-end;
    text-align: right;
    color: #f94450; }

.Popup {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; }

.Popup .Popup-contents {
  display: flex;
  align-items: center;
  justify-content: center; }

.Popup .Popup-contents {
  flex-direction: column; }

@-webkit-keyframes blink {
  50% {
    opacity: 0; } }

@keyframes blink {
  50% {
    opacity: 0; } }

.Popup {
  z-index: 50;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center; }
  .Popup .Popup-contents {
    box-sizing: border-box;
    max-height: 80%;
    min-height: 45%;
    width: 35%;
    background: white;
    position: relative;
    text-align: center;
    justify-content: space-between;
    padding: 3%;
    overflow: hidden; }

.ReportUserForm {
  display: flex;
  align-items: center;
  justify-content: center; }

.ReportUserForm {
  flex-direction: column; }

.ReportUserForm .ReportUserForm-buttons {
  display: flex;
  align-items: center; }

@-webkit-keyframes blink {
  50% {
    opacity: 0; } }

@keyframes blink {
  50% {
    opacity: 0; } }

.ReportUserForm .TextArea {
  height: 28vh;
  min-width: 72vw;
  margin-bottom: 10vmin; }
  .ReportUserForm .TextArea .TextArea-textarea {
    text-align: center; }

.ReportUserForm .ReportUserForm-buttons {
  width: 100%;
  justify-content: space-between; }

@-webkit-keyframes blink {
  50% {
    opacity: 0; } }

@keyframes blink {
  50% {
    opacity: 0; } }

.ReportUser .Popup-contents {
  background-color: #f3eee0;
  width: 88%;
  max-height: 94%; }

.Chatroom {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; }

.Chatroom .Chatroom-body .Chatroom-content, .Chatroom .Chatroom-body {
  display: flex;
  align-items: center;
  justify-content: center; }

.Chatroom .Chatroom-body .Chatroom-content {
  flex-direction: column; }

.Chatroom .Chatroom-body .Chatroom-content .Chatroom-header {
  display: flex;
  align-items: center; }

@-webkit-keyframes blink {
  50% {
    opacity: 0; } }

@keyframes blink {
  50% {
    opacity: 0; } }

.Chatroom .Chatroom-body .Chatroom-content .Chatroom-messages .Message, .Chatroom .Chatroom-body .Chatroom-content .Chatroom-textarea {
  margin-left: 2vmin;
  margin-right: 2vmin; }

.Chatroom .Header {
  margin-bottom: 0; }

.Chatroom .Chatroom-body .Chatroom-content {
  width: 100vmin; }
  .Chatroom .Chatroom-body .Chatroom-content .Chatroom-header {
    font-size: 3.6vmin;
    font-weight: 700;
    margin-bottom: 5vh; }
  .Chatroom .Chatroom-body .Chatroom-content .Chatroom-messages {
    height: 45vh;
    width: 100%;
    overflow: scroll; }
  .Chatroom .Chatroom-body .Chatroom-content .Chatroom-textarea {
    width: calc(100% - 4vmin); }
    .Chatroom .Chatroom-body .Chatroom-content .Chatroom-textarea .TextArea {
      height: 15vh; }

.PotentialMatch {
  display: flex;
  align-items: center;
  justify-content: center; }

.PotentialMatch {
  flex-direction: column; }

.PotentialMatch .PotentialMatch-info, .PotentialMatch .PotentialMatch-question .PotentialMatch-question-header {
  display: flex;
  align-items: center; }

@-webkit-keyframes blink {
  50% {
    opacity: 0; } }

@keyframes blink {
  50% {
    opacity: 0; } }

.PotentialMatch {
  width: 100vmin; }
  .PotentialMatch .PotentialMatch-info {
    margin: 0 2vmin;
    font-size: 4vmin;
    font-weight: 700; }
    .PotentialMatch .PotentialMatch-info .PotentialMatch-info-firstname {
      color: #f94450; }
    .PotentialMatch .PotentialMatch-info .PotentialMatch-info-age {
      margin: 0 1.5vmin; }
    .PotentialMatch .PotentialMatch-info .PotentialMatch-info-location {
      font-size: 3.6vmin;
      font-weight: 400; }
  .PotentialMatch .PotentialMatch-question {
    width: 96vmin;
    padding: 2vmin;
    margin: 0 2vmin; }
    .PotentialMatch .PotentialMatch-question .PotentialMatch-question-header {
      font-size: 4vmin;
      line-height: 0;
      font-weight: 700;
      margin: 4vmin 0 0 0; }
      .PotentialMatch .PotentialMatch-question .PotentialMatch-question-header .Button {
        display: inline-block;
        margin: 0 0 0 3vmin; }
    .PotentialMatch .PotentialMatch-question .PotentialMatch-question-text {
      font-size: 2.6vmin;
      margin-top: 0.4vmin;
      max-width: 77vw;
      font-weight: 700; }
    .PotentialMatch .PotentialMatch-question .PotentialMatch-answer-text {
      font-size: 6vmin;
      color: #f94450;
      margin-top: 1.4vmin; }
    .PotentialMatch .PotentialMatch-question .ReportUser {
      margin-top: 2vmin; }
      .PotentialMatch .PotentialMatch-question .ReportUser .ReportUser-button {
        margin-left: auto; }
    .PotentialMatch .PotentialMatch-question.questionexpanded .PotentialMatch-question-header {
      font-size: 3vmin; }
    .PotentialMatch .PotentialMatch-question.questionexpanded .PotentialMatch-question-text {
      font-size: 6vmin;
      line-height: 7vmin;
      margin-top: 0; }
    .PotentialMatch .PotentialMatch-question.questionexpanded .PotentialMatch-answer-text {
      font-size: 4vmin;
      font-weight: 400;
      margin-top: 2vmin; }

.PotentialMatches {
  display: flex;
  align-items: center;
  justify-content: center; }

.PotentialMatches {
  flex-direction: column; }

.PotentialMatches .PotentialMatches-buttons {
  display: flex;
  align-items: center; }

@-webkit-keyframes blink {
  50% {
    opacity: 0; } }

@keyframes blink {
  50% {
    opacity: 0; } }

.PotentialMatches {
  justify-content: space-between;
  height: 85vmin; }
  .PotentialMatches .PotentialMatches-match {
    display: none; }
    .PotentialMatches .PotentialMatches-match.visible {
      display: block; }
  .PotentialMatches .PotentialMatches-buttons {
    width: 100vmin;
    justify-content: space-between; }

.Feed {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; }

.Feed .Feed-content {
  display: flex;
  align-items: center;
  justify-content: center; }

.Feed .Feed-content {
  flex-direction: column; }

@-webkit-keyframes blink {
  50% {
    opacity: 0; } }

@keyframes blink {
  50% {
    opacity: 0; } }

.Feed .Feed-content {
  height: 70vmin; }

.DeleteAccount {
  display: flex;
  align-items: center;
  justify-content: center; }

.DeleteAccount {
  flex-direction: column; }

@-webkit-keyframes blink {
  50% {
    opacity: 0; } }

@keyframes blink {
  50% {
    opacity: 0; } }

.DeleteAccount .DeleteAccount-header {
  text-align: center;
  font-size: 9vmin;
  margin: 12vmin 2vmin 6vmin 2vmin; }

.DeleteAccount .DeleteAccount-subheader {
  margin: 14vmin; }

.DeleteAccount .Button {
  margin: 4vmin; }

@-webkit-keyframes blink {
  50% {
    opacity: 0; } }
@keyframes blink {
  50% {
    opacity: 0; } }

.Main {
  display: flex;
  align-items: center;
  justify-content: center; }

.Main {
  flex-direction: column; }

@-webkit-keyframes blink {
  50% {
    opacity: 0; } }

@keyframes blink {
  50% {
    opacity: 0; } }

.Main {
  background-color: #f3eee0; }

@-webkit-keyframes blink {
  50% {
    opacity: 0; } }

@keyframes blink {
  50% {
    opacity: 0; } }

.Privacy {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  padding: 1em;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: white;
  color: black; }

.SiteMain {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; }

.SiteMain {
  display: flex;
  align-items: center;
  justify-content: center; }

.SiteMain {
  flex-direction: column; }

@-webkit-keyframes blink {
  50% {
    opacity: 0; } }

@keyframes blink {
  50% {
    opacity: 0; } }

.SiteMain {
  background-color: #f3eee0; }

.GlobalLoader {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; }

.GlobalLoader {
  display: flex;
  align-items: center;
  justify-content: center; }

@-webkit-keyframes blink {
  50% {
    opacity: 0; } }

@keyframes blink {
  50% {
    opacity: 0; } }

.GlobalLoader {
  background-color: #f3eee0;
  z-index: -1;
  opacity: 0; }
  .GlobalLoader.loading {
    z-index: 1000;
    opacity: 1; }
    .GlobalLoader.loading .Logo {
      opacity: 1; }
  .GlobalLoader.loading-finished {
    opacity: 1;
    -webkit-animation: fadeout 2s, allowClicks 1s;
            animation: fadeout 2s, allowClicks 1s;
    -webkit-animation-delay: 1.2s;
            animation-delay: 1.2s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    z-index: -1;
    transition: z-index 0s 3s; }
    .GlobalLoader.loading-finished .Logo {
      opacity: 1;
      -webkit-animation: fadeout 1.2s;
              animation: fadeout 1.2s;
      -webkit-animation-fill-mode: forwards;
              animation-fill-mode: forwards; }

@-webkit-keyframes fadeout {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeout {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@-webkit-keyframes allowClicks {
  from {
    pointer-events: auto; }
  to {
    pointer-events: none; } }

@keyframes allowClicks {
  from {
    pointer-events: auto; }
  to {
    pointer-events: none; } }

.App {
  display: flex;
  align-items: center;
  justify-content: center; }

.App {
  flex-direction: column; }

@-webkit-keyframes blink {
  50% {
    opacity: 0; } }

@keyframes blink {
  50% {
    opacity: 0; } }


@import '../../../styles/variables';

$text-color: $accent;

.TextArea {
	height: 100%;
	width: 100%;
	.TextArea-box {
		height: 100%;
		width: 100%;
		display: block;
	}
	.TextArea-caret {
		@extend %blink;
		background-color: $text-color;
		width: 0.6vmin;
		height: 5.8vmin;
		position: absolute;
		display: none;
	}
	.TextArea-textarea {
		@extend %main-font;
		height: 100%;
		width: 100%;
		background-color: $clear;
		border: none;
		font-size: 4vmin;
		color: $text-color;
		resize: none;
		overflow: hidden;
		&:focus {
			outline: none;
		}
	}
	&.showFakeCaret{
		.TextArea-caret {
			display: block;
		}
		.TextArea-textarea {
			caret-color: $clear;
		}
	}
}
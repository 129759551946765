@import '../styles/variables';

.Header {
	@extend %flex-horizontal-center;
	justify-content: space-between;
	height: 10vmin;
	.Button {
		margin: 2vmin;
	}
	// margin-bottom: 5vmin;
}